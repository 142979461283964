import React, {Component} from 'react'
import DefaultTemplate from "../templates/default"
import Contact from "../components/home/contact"
import AOS from 'aos';
import 'aos/dist/aos.css';

class Support extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount(){
        AOS.init({
            once: true
        })
    }

    render() {
    return <DefaultTemplate>
                <Contact />
            </DefaultTemplate>
    }
}

export default Support
   